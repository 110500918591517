// Colours

// Black & White
$color-white: #ffffff;
$color-white-f7: #f7f7f7;
$color-black: #3b3b3b;
$keyline-button-hover-background: #f5f5f5;
$color-grey-superlight: #f5f6f8;
$color-grey-transparent-bg: #f4f4f4;

// Grey
$color-grey-dark: #748296;
$color-grey-6c: #6c757d;
$color-grey-darker: #bababa;
$color-grey: #a2abb8;
$color-grey-light: #dadee3;
$color-grey-superlight: #f7f9fc;
$color-placeholder: rgba($color-black, 0.4);
$color-background: #ecf0f6;
$color-grey-53: #535353;
$color-grey-A9: #a9a9a9;
$color-grey-DA: #dadada;
$color-grey-E8: #e8e8e8;
$color-grey-FA: #fafafa;

// Red
$color-warning: #ff3c41;
$color-dislike: #d26059;
$color-error: #ff3c41;
$color-error-text: #c80d12;
$color-error-hover: #ff0008;
$color-red: $color-warning;
$color-red-dark: #e20002;
$color-red-superlight: #ffeaed;

// Green
$color-focused: #70d677;
$color-primary: #28b681;
$color-green: $color-primary;
$color-dark-green: #61c368;
$color-like: #56dd93;
$color-green-very-light: #e3f4ec;
$color-green-600: #008855;
$color-green-700: #005832;

// Blue
$color-blue: #4a77bc;
$color-blue-light: #7aa7db;
$color-blue-text-bg: #e6f2f5;

// Yellow
$color-alert: #ffc72f;
$color-yellow: $color-alert;
$color-yellow-dark: #edb405;

// Text
$font-size-sm: 0.8rem;
$font-size-header: 18px;
$font-size-header-line-height: 26px;
$font-size-body: 14px;
$font-size-body-mobile: 16px;

// Layout
$max-stage-width: 600px;
$main-container-header: 0.3rem;
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1340px,
);

// Card sizing
$card-height: 100vh;
$max-card-height: 75vh;
$card-padding: 30px;

// Responsive breakpoint values
$screen-xxs-min: 300px; // Tiny phones
$screen-xs-min: 425px; // Small phones
$screen-sm-min: 576px; // Small tablets and large smartphones (landscape view)
$screen-md-min: 768px; // Small tablets (portrait view)
$screen-lg-min: 992px; // Tablets and small desktops
$screen-xl-min: 1200px; // Large tablets and desktops

// Border radius
$border-radius-default: 0.4rem;
$border-radius-full: 2rem;

// Shadows
$container-drop-shadow: 0px 5px 12px rgba(0, 0, 0, 0.16); // 0px 2px 12px rgba($color-black, 0.1);
$button-drop-shadow: 0px 5px 12px rgba($color-black, 0.2);
$button-drop-shadow-hover: 0px 1px 5px rgba($color-black, 0);
$section-drop-shadow: 0px 10px 15px rgba($color-white, 0.07);

// Transitions
$transition-element: all 0.1s ease 0s;

// Transformations
$transform-depress: scale(0.98);

// Spacing between inline elements like buttons beside each other
$element-inline-margin: 0.5rem;
