@import "variables";@import "mixins";
.asset-with-text {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	> div {
		width: 100%;
	}

	.video-player {
		margin-top: 14px;
	}

	&__image {
		margin: 14px 0px 30px 0px;

		& img {
			max-width: 100%;
			max-height: 40vh;

			@media only screen and (max-width: $max-stage-width) {
				max-height: 30vh;
			}
		}

		&.center {
			text-align: center;
		}
	}

	&__title {
		font-size: 20px;
		line-height: 25px;
	}

	&__summary {
		font-size: 14px;
		line-height: 22px;
		color: $color-black;

		.ql-indent-1 {
			padding-left: 3rem !important;
		}

		.ql-indent-2 {
			padding-left: 6rem !important;
		}

		.ql-indent-3 {
			padding-left: 9rem !important;
		}

		.ql-indent-4 {
			padding-left: 12rem !important;
		}

		.ql-indent-5 {
			padding-left: 15rem !important;
		}

		.ql-indent-6 {
			padding-left: 18rem !important;
		}

		.ql-indent-7 {
			padding-left: 21rem !important;
		}

		.ql-indent-8 {
			padding-left: 24rem !important;
		}

		.ql-align-center {
			text-align: center;
		}

		.ql-align-left {
			text-align: left;
		}

		.ql-align-right {
			text-align: right;
		}

		.ql-size-small {
			font-size: 11px;
			line-height: 1.5;
		}

		.ql-size-large {
			font-size: 24px;
			line-height: 36px;
		}
	}

	ol,
	ul {
		list-style-type: none;

		li[data-list='ordered'] {
			counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
			counter-increment: list-0;

			&:before {
				content: counter(list-0, decimal) '. ';
			}

			&::marker {
				display: none;
				content: none;
			}

			&.ql-indent-1 {
				counter-increment: list-1;
				margin-left: 3rem !important;
				padding-left: 0rem !important;
				counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8;
			}

			&.ql-indent-2 {
				counter-increment: list-2;
				margin-left: 6rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-3 list-4 list-5 list-6 list-7 list-8;
			}

			&.ql-indent-3 {
				counter-increment: list-3;
				margin-left: 9rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-4 list-5 list-6 list-7 list-8;
			}

			&.ql-indent-4 {
				counter-increment: list-4;
				margin-left: 12rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-5 list-6 list-7 list-8;
			}

			&.ql-indent-5 {
				counter-increment: list-5;
				margin-left: 15rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-4 list-6 list-7 list-8;
			}

			&.ql-indent-6 {
				counter-increment: list-6;
				margin-left: 18rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-4 list-6 list-7 list-8;
			}

			&.ql-indent-7 {
				counter-increment: list-7;
				margin-left: 21rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-8;
			}

			&.ql-indent-8 {
				counter-increment: list-8;
				margin-left: 24rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7;
			}
		}
	}

	ol li[data-list='bullet'] {
		&.ql-indent-1::before,
		&.ql-indent-2::before,
		&.ql-indent-3::before,
		&.ql-indent-4::before,
		&.ql-indent-5::before,
		&.ql-indent-6::before,
		&.ql-indent-7::before,
		&.ql-indent-8::before,
		&:before {
			content: counter(list-0, disc) ' ' !important;
		}
	}

	ol li {
		&.ql-indent-1::before {
			content: counter(list-1, lower-alpha) '. ' !important;
		}

		&.ql-indent-2::before {
			content: counter(list-2, lower-roman) '. ' !important;
		}

		&.ql-indent-3::before {
			content: counter(list-3, decimal) '. ' !important;
		}

		&.ql-indent-4::before {
			content: counter(list-4, lower-alpha) '. ' !important;
		}

		&.ql-indent-5::before {
			content: counter(list-5, lower-roman) '. ' !important;
		}

		&.ql-indent-6::before {
			content: counter(list-6, decimal) '. ' !important;
		}

		&.ql-indent-7::before {
			content: counter(list-7, lower-alpha) '. ' !important;
		}

		&.ql-indent-8::before {
			content: counter(list-8, lower-roman) '. ' !important;
		}
	}
}
