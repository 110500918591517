@import "variables";@import "mixins";
.main-container-end {
  &__loader {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 200px;
  }
  &__welcome {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		.loader-0 {
			margin-bottom: 42px;
		}
	}
	&__welcome-container {
		text-align: center;
		max-width: 421px;
		min-height: 174px;
		align-items: center;
		display: flex;
		flex-direction: column;
		> button {
			max-width: 372px;
		}
	}
	&__welcome-content {
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		text-align: center;
		margin-top: 0px;
		max-width: 340px;
	}
	&__welcome-title {
		font-size: 25px;
		font-weight: 600;
		line-height: 38px;
		text-align: center;
		margin-top: 0px;
	}
}
