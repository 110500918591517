@import "variables";@import "mixins";
$devicePreviewBannerHeight: 40px;

.device-preview-header + .scroll-container.ios-mobile,
.device-preview-header + .scroll-container.android-mobile {
	.questions-container {
		margin-top: -40px;
	}
}

.question {
	&__question-image {
		padding-bottom: 10px;

		img {
			max-width: 100%;
			max-height: 33vh;

			@media only screen and (max-width: $max-stage-width) {
				max-height: 20vh;
			}
		}

		&.center {
			text-align: center;
		}
	}

	.label {
		display: block;
		text-align: left;

		.body.rtl & {
			text-align: right;
		}

		.other-label {
			margin-top: 30px;
		}
	}

	.question-help {
		font-size: 12px;
		margin-top: 14px;
		margin-bottom: 14px;

		&.error {
			color: $color-error-text;
		}
	}

	&__other-input {
		position: absolute;
		transform: translateY(-50%); // Position other please specify center aligned on radio btn
		box-shadow: none;
	}
}
