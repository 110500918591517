@import "variables";@import "mixins";
$footerHeight: 80px; // 68px footer height + 12px padding

.questions-container {
	// overflow: auto;
	// height: calc(100vh - 80px);
	text-align: left;

	hyphens: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: calc(100vh - 10px); // - scroll container top padding

	&__heading {
		box-shadow: $section-drop-shadow;
	}

	&__questions {
		padding: 0px 20px 0px 20px;
	}

	&__inline-button {
		// display: none;
		padding: 15px 0px;
		width: 100%;
	}

	&__inline-button-container {
		display: flex;
		justify-content: center;
		display: -webkit-flex;
		-webkit-justify-content: center;
	}

	.ql-size-small {
		font-size: 11px;
		line-height: 1.5;
	}

	.ql-size-large {
		font-size: 24px;
		line-height: 36px;
	}

	.ql-direction-rtl {
		direction: rtl;
		text-align: inherit;
	}

	.ql-align-center {
		text-align: center;
	}

	.ql-align-right {
		text-align: right;
	}

	.ql-align-justify {
		text-align: justify;
	}

	.ql-indent-1 {
		padding-left: 3rem !important;
	}

	.ql-indent-2 {
		padding-left: 6rem !important;
	}

	.ql-indent-3 {
		padding-left: 9rem !important;
	}

	.ql-indent-4 {
		padding-left: 12rem !important;
	}

	.ql-indent-5 {
		padding-left: 15rem !important;
	}

	.ql-indent-6 {
		padding-left: 18rem !important;
	}

	.ql-indent-7 {
		padding-left: 21rem !important;
	}

	.ql-indent-8 {
		padding-left: 24rem !important;
	}

	ol {
		padding-left: 0px;
	}

	ol,
	ul {
		list-style-type: none;

		li[data-list='ordered'] {
			&::marker {
				display: none;
				content: none;
			}

			counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
			counter-increment: list-0;

			&:before {
				content: counter(list-0, decimal) '. ';
			}

			&.ql-indent-1 {
				counter-increment: list-1;
				margin-left: 3rem !important;
				padding-left: 0rem !important;
				counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8;
			}

			&.ql-indent-2 {
				counter-increment: list-2;
				margin-left: 6rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-3 list-4 list-5 list-6 list-7 list-8;
			}

			&.ql-indent-3 {
				counter-increment: list-3;
				margin-left: 9rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-4 list-5 list-6 list-7 list-8;
			}

			&.ql-indent-4 {
				counter-increment: list-4;
				margin-left: 12rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-5 list-6 list-7 list-8;
			}

			&.ql-indent-5 {
				counter-increment: list-5;
				margin-left: 15rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-4 list-6 list-7 list-8;
			}

			&.ql-indent-6 {
				counter-increment: list-6;
				margin-left: 18rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-4 list-6 list-7 list-8;
			}

			&.ql-indent-7 {
				counter-increment: list-7;
				margin-left: 21rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-8;
			}

			&.ql-indent-8 {
				counter-increment: list-8;
				margin-left: 24rem !important;
				padding-left: 0rem !important;
				counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7;
			}
		}
	}

	ol li[data-list='bullet'] {
		&.ql-indent-1::before,
		&.ql-indent-2::before,
		&.ql-indent-3::before,
		&.ql-indent-4::before,
		&.ql-indent-5::before,
		&.ql-indent-6::before,
		&.ql-indent-7::before,
		&.ql-indent-8::before,
		&:before {
			content: counter(list-0, disc) ' ' !important;
		}
	}

	ol li {
		&.ql-indent-1::before {
			content: counter(list-1, lower-alpha) '. ' !important;
		}

		&.ql-indent-2::before {
			content: counter(list-2, lower-roman) '. ' !important;
		}

		&.ql-indent-3::before {
			content: counter(list-3, decimal) '. ' !important;
		}

		&.ql-indent-4::before {
			content: counter(list-4, lower-alpha) '. ' !important;
		}

		&.ql-indent-5::before {
			content: counter(list-5, lower-roman) '. ' !important;
		}

		&.ql-indent-6::before {
			content: counter(list-6, decimal) '. ' !important;
		}

		&.ql-indent-7::before {
			content: counter(list-7, lower-alpha) '. ' !important;
		}

		&.ql-indent-8::before {
			content: counter(list-8, lower-roman) '. ' !important;
		}
	}

	.left {
		text-align: left;
	}

	// Component library overrides
	.radio-button.checked {
		background-color: rgb(227, 244, 236);
		border-color: #bababa !important;
	}
	.radio-button--input:checked + .radio-button--checkbox {
		border-color: $color-green-600 !important;
	}
}
