@import "variables";@import "mixins";
.ranked-option-checkbox {
	height: 22px;
	width: 22px;
	outline: 2px solid $color-grey-darker;
	border-radius: 4px;
	align-items: center;
	justify-content: center;
	display: none;

	&__position {
		color: $color-white;
		font-weight: 400;
	}
}

.ranked-option-checkbox__has-position {
	display: flex;
	outline: none;
	height: 24px;
	width: 24px;
	background: $color-green-600;
	flex-shrink: 0;
}
